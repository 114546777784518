<template>
    <div class="card">
        <div class="card-header">{{ $t('dashboard.charts.title') }}</div>
        <div class="card-body">
        <div class="row">
            <div class="col-12">
                <form class="form-inline mb-2 justify-content-between" @submit.prevent="load">
                    <b-input-group size="sm">
                        <div class="btn-group btn-group-sm">
                            <button :class="['btn btn-sm btn-outline-secondary', isActive('this-year') ? 'active' : '']" @click="changeDate('this-year')">{{ $t('dashboard.charts.this_year') }}</button>
                            <button :class="['btn btn-sm btn-outline-secondary', isActive('last-month') ? 'active' : '']" @click="changeDate('last-month')">{{ $t('dashboard.charts.last_month') }}</button>
                            <button :class="['btn btn-sm btn-outline-secondary', isActive('this-month') ? 'active' : '']" @click="changeDate('this-month')">{{ $t('dashboard.charts.this_month') }}</button>
                            <button :class="['btn btn-sm btn-outline-secondary', isActive('last-7-days') ? 'active' : '']" @click="changeDate('last-7-days')">{{ $t('dashboard.charts.last_7_days') }}</button>
                            <loading-button v-if="loading" :loading="loading" class="btn btn-info text-white"></loading-button>
                        </div>
                    </b-input-group>
                    <b-input-group size="sm">
                        <input type="date" class="form-control mr-2 input-sm" v-model="start" />
                        <input type="date" class="form-control mr-2" v-model="end" />
                        <button type="submit" class="btn btn-outline-secondary btn-sm">{{ $t('dashboard.charts.submit') }}</button>
                    </b-input-group>
                </form>
            </div>
            <div class="col-lg-2 d-flex">
                <div class="btn-group-vertical w-100">
                    <div class="card w-100 mb-0">
                        <div class="card-body p-0 text-dark text-center">
                            <template v-if="loaded">
                                <div class="p-2" v-b-tooltip title="Som van bestellingtotaal, incl. verzendkosten en belasting" style="cursor: help">
                                    <h5 class="m-0">{{ data.bruto_revenue | toCurrency }}</h5>
                                    <small class="text-muted">Bruto verkoop in deze periode</small>
                                </div>
                                <hr class="m-1"/>
                                <div class="p-2">
                                    <h5 class="m-0">{{ data.bruto_daily_average | toCurrency }}</h5>
                                    <small class="text-muted">Gemiddelde bruto dagelijkse verkoop</small>
                                </div>
                                <hr class="m-1"/>
                                <div class="p-2" v-b-tooltip title="Som van bestellingtotaal, excl. verzendkosten en belasting" style="cursor: help">
                                    <h5 class="m-0">{{ data.netto_revenue | toCurrency }}</h5>
                                    <small class="text-muted">Netto verkoop in deze periode</small>
                                </div>
                                <hr class="m-1"/>
                                <div class="p-2">
                                    <h5 class="m-0">{{ data.netto_daily_average | toCurrency }}</h5>
                                    <small class="text-muted">Gemiddelde dagelijkse netto omzet</small>
                                </div>
                                <hr class="m-1"/>
                                <div class="p-2">
                                    <h5 class="m-0">{{ data.orders_count }}</h5>
                                    <small class="text-muted">Bestellingen geplaatst</small>
                                </div>
                                <hr class="m-1"/>
                                <div class="p-2">
                                    <h5 class="m-0">{{ data.orders_m2_sum }}</h5>
                                    <small class="text-muted">Aantal m² gekocht</small>
                                </div>
                                <hr class="m-1"/>
                                <div class="p-2">
                                    <h5 class="m-0">{{ data.refunded_sum | toCurrency }}</h5>
                                    <small class="text-muted">Terugbetaald {{ data.orders_refunded_count }} bestelling(en)</small>
                                </div>
                                <hr class="m-1"/>
                                <div class="p-2">
                                    <h5 class="m-0">{{ data.delivery_sum | toCurrency }}</h5>
                                    <small class="text-muted">Berekend voor verzending</small>
                                </div>
                            </template>
                            <loading v-else class="m-3"></loading>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-10">
                <div class="card mb-0 h-100">
                    <div class="card-body">
                        <div ref="chart" style="width: 100%;"></div>
                    </div>
                </div>
            </div>
        </div>
     </div>
    </div>
</template>

<script>
    import {GoogleCharts} from 'google-charts';
    export default {
        mounted() {
            GoogleCharts.load(this.load);
        },
        data() {
            return {
                data: {},
                start: this.$route.query.start || this.$moment().startOf('month').format('YYYY-MM-DD'),
                end: this.$route.query.end || this.$moment().endOf('month').format('YYYY-MM-DD'),
                loaded: false,
                loading: false
            }
        },
        methods: {
            /**
             * Change dates to a preset range and load the data.
             * @param {String} type
             */
            changeDate(type) {
                let start, end;
                switch(type) {
                    case 'this-year':
                        start = this.$moment().startOf('year');
                        end = this.$moment().endOf('year');
                        break;
                    case 'last-month':
                        start = this.$moment().subtract(1, 'month').startOf('month');
                        end = this.$moment().subtract(1, 'month').endOf('month');
                        break;
                    case 'this-month':
                        start = this.$moment().startOf('month');
                        end = this.$moment().endOf('month');
                        break;
                    case 'last-7-days':
                        start = this.$moment().subtract(7, 'days');
                        end = this.$moment();
                        break;
                }

                this.start = start.format('YYYY-MM-DD');
                this.end = end.format('YYYY-MM-DD');

                this.load();
            },
            /**
             * Check if date ranges are active for a button.
             * @param {String} type
             * @return {Boolean}
             */
            isActive(type) {
                switch(type) {
                    case 'this-year':
                        return this.start === this.$moment().startOf('year').format('YYYY-MM-DD') &&
                                this.end === this.$moment().endOf('year').format('YYYY-MM-DD');
                    case 'last-month':
                        return this.start === this.$moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD') &&
                            this.end === this.$moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
                    case 'this-month':
                        return this.start === this.$moment().startOf('month').format('YYYY-MM-DD') &&
                            this.end === this.$moment().endOf('month').format('YYYY-MM-DD');
                    case 'last-7-days':
                        return this.start === this.$moment().subtract(7, 'days').format('YYYY-MM-DD') &&
                            this.end === this.$moment().format('YYYY-MM-DD');
                }
                return false;
            },
            load() {
              if(!this.$auth.user().is_admin) return;

                this.loading = true;
                this.loadData(this.start, this.end).then(() => {
                    return this.formatData(this.data.orders);
                }).then((data) => {
                    this.loading = false;
                    this.loadChart(data);
                }).catch(() => {
                    this.loading = false;
                });
            },
            /**
             * @return {Promise}
             */
            loadData(start, end) {
                history.replaceState(null, null, document.location.pathname + '#' + this.$route.path + '?start=' + start + '&end=' + end);
                return this.$http.get(`reports/chart?start=${start}&end=${end}`).then((response) => {
                    this.data = response.data;
                });
            },
            /**
             * Format data into Google chart format.
             * @param {Object} orders
             * @return {string[][]}
             */
            formatData(orders) {
                let chart_data = [
                    ['unused', 'Bruto', 'Netto', 'Delivery', 'Refunded']
                ];
                for(let date in orders) {
                    if(orders.hasOwnProperty(date)) {
                        let order = orders[date];
                        chart_data.push([
                            date,
                            parseFloat(order.bruto_revenue_sum),
                            parseFloat(order.netto_revenue_sum),
                            parseFloat(order.delivery_sum),
                            parseFloat(order.refunded_sum),
                        ]);
                    }
                }
                return chart_data;
            },
            /**
             * @param {Object} data
             * @param data
             */
            loadChart(data) {
                // Standard google charts functionality is available as GoogleCharts.api after load
                data = GoogleCharts.api.visualization.arrayToDataTable(data);

                let formatter = new GoogleCharts.api.visualization.NumberFormat({
                    fractionDigits: 2,
                    prefix: '€ '
                });
                formatter.format(data, 1);
                formatter.format(data, 2);
                formatter.format(data, 3);
                formatter.format(data, 4);

                const pie_1_chart = new GoogleCharts.api.visualization.LineChart(this.$refs.chart);
                pie_1_chart.draw(data, {
                    height: 470,
                    fontSize: 12,
                    color: 'blue',
                    auraColor: 'red',
                    chartArea:{
                        width:'80%',
                        height:'65%'
                    },
                    explorer: {},
                    hAxis: {
                        title: 'Datum',
                        gridlines: {
                            color: 'lime',
                        },
                        titleTextStyle: {
                            color: '#555',
                            italic: false
                        },
                        textStyle: {
                            fontSize: 10
                        },
                        slantedText:true, slantedTextAngle:70,
                        baselineColor: '#555',
                    },
                    vAxis: {
                        title: this.$t('dashboard.charts.amount'),
                        format: '€ #,###',
                        gridlines: {
                            color: '#ddd',
                        },
                        titleTextStyle: {
                            color: '#555',
                            italic: false
                        },
                        baselineColor: '#555',
                    }
                });
                this.loaded = true;
            }
        }
    }
</script>
