<template>
    <b-row class="animated fadeIn">
        <b-col cols="12">
            <div class="card">
                <div class="card-header">
                    Dashboard
                </div>
                <div class="card-body">
                    <div class="row">
                        <b-col cols="12" sm="6" lg="4">
                            <b-row>
                                <b-col cols="12">
                                    <b-card :no-body="true">
                                        <b-card-body class="p-0 clearfix">
                                            <i class="fa fa-line-chart bg-info p-4 font-2xl mr-3 float-left"></i>
                                            <div class="h5 text-info mb-0 pt-3">{{ dashboard.net_revenue | toCurrency }}</div>
                                            <div class="text-muted text-uppercase font-weight-bold font-xs">{{ $t('dashboard.net_revenue') }}</div>
                                        </b-card-body>
                                    </b-card>
                                </b-col>
                                <b-col cols="12">
                                    <div class="brand-card">
                                        <div class="brand-card-body">
                                            <div>
                                                <div class="text-value">{{ dashboard.orders_processing_count }}</div>
                                                <div class="small"><router-link class="text-uppercase text-success" to="/orders?filters={&quot;status&quot;:[3]}">{{ $t('dashboard.orders_processing') }}</router-link></div>
                                            </div>
                                            <div>
                                                <div class="text-value">{{ dashboard.orders_on_hold_count }}</div>
                                                <div class="small"><router-link class="text-uppercase text-secondary" to="/orders?filters={&quot;status&quot;:[1]}">{{ $t('dashboard.orders_on_hold') }}</router-link></div>
                                            </div>
                                        </div>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col cols="12" sm="6" lg="4">
                            <b-card :no-body="true">
                                <p class="m-2 text-muted text-uppercase font-weight-bold font-xs">
                                    <span>{{ $t('dashboard.overview_pickup') }}</span>
                                    <span class="pull-right">{{ $moment(dashboard.pickup_date).format('dddd DD-MM-YYYY') }}</span>
                                </p>
                                <table class="table m-2">
                                    <tr v-for="pickup_location in dashboard.pickup_locations">
                                        <td>{{ $t('dashboard.pickup', {
                                                quantity: pickup_location.quantity_sum,
                                                name: pickup_location.name,
                                                city: pickup_location.city
                                            }) }}
                                        </td>
                                    </tr>
                                </table>
                            </b-card>
                        </b-col>
                    </div>
                    <hr />
                    <charts-component></charts-component>
                </div>
            </div>
        </b-col>
    </b-row>
</template>

<script>
    import {Callout} from '@coreui/vue';
    import ChartsComponent from './Charts';

    export default {
        name: 'dashboard',
        components: {
            Callout,
            ChartsComponent
        },
      beforeRouteEnter(to, from, next) {
          next(vm => {
            const role = vm.$auth.user().is_admin;

            switch(role) {
              case 0:
                next({name: 'pages.index'});
                break;
              case 2:
                next({name: 'administration.index'});
                break;
              case 3:
                next({name: 'orders.index'});
                break;
            }
          });
      },
        mounted() {
            this.getDashboardData();
        },
        data() {
            return {
                dashboard: {
                    net_revenue: 0,
                    orders_processing_count: 0,
                    orders_on_hold_count: 0,
                    pickup_locations: [],
                    pickup_date: undefined
                }
            };
        },
        methods: {
            getDashboardData() {
              if(this.$auth.user().is_admin != 1) return;

                this.$http.get('reports/dashboard').then((response) => {
                    this.dashboard = response.data;
                })
            }
        }
    }
</script>
